import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';

export const CookiePolicyDialogStyle = styled(Dialog)(
  ({ theme }) => `
  .MuiDialogActions-root {
    display: flex;
    justify-content: center;
    padding-bottom: 24px;
  }

  .MuiDialogTitle-root {
    font-size: 24px;
    text-align: center;
    font-weight: bold;
    color: ${theme.palette.primary.main};
    padding-bottom: 0px;

    svg {
      height: 50px;
      color: #BDBDBD;
      width: 50px;
    }
  }

  .MuiDialogContent-root {
    padding-bottom: 16px;

    .MuiDialogContentText-root {
      font-family: Lanna;
      font-size: 18px;
      text-align: center;

      color: ${theme.palette.text.secondary};

      a {
        color: ${theme.palette.primary.main};
        svg {
          font-size: 12px;
        }
      }
    }
  }

  .MuiButton-root {
    width: 140px;
    margin: 0px 8px;
  }
`,
);

export const CookiePolicyFooterStyle = styled(Box)(
  ({ theme }) => `
  position: fixed;
  bottom: 0px;
  z-index: 99;
  width: 100%; 
  padding: 0px 20px;
  height: 150px;
  
  .container {
    height: 150px;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
    border-radius: 10px 10px 0px 0px;
    background: white;

    .fa-cookie-bite {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      padding: 12px;
      color: ${theme.palette.secondary.main};
      background: #F5F5F5;
    }

    .text-container {
      .MuiTypography-root {
        margin-right: 8px;

        a {
          color: ${theme.palette.primary.main};
        }
      }
    }

    .policy-content {
      margin-right: 8px;
    }
  }

  ${theme.breakpoints.down('lg')} {
    padding: 0px 15px;
    height: unset;
    .container {
      height: unset;
      padding: 40px 20px;
    }
  }
  ${theme.breakpoints.down('sm')} {
    .container {
      padding: 24px 15px;
    }
  }

  @media (min-width: 1440px) {
    .new-line {
      display: none;
    }
  }
`,
);

export const CookiePolicyModalActionContainer = styled(Box)(
  ({ theme }) => `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${theme.breakpoints.down('sm')} {
    .MuiButton-root {
      width: 100%;
      margin-left: 0px;
      margin-right: 0px;
    }
  }
  `,
);
